<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <!-- layer : 폰트/이미지 설정 -->
    <section id="modalResultFont" class="layer_dialog modalin">
      <h2 class="mb20">폰트/이미지 설정</h2>
      <table class="tbl_noline type2">
        <caption>
          폰트/이미지 설정
        </caption>
        <colgroup>
          <col style="width: 25%" />
          <col style="width: 75%" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">상품명</th>
            <td class="input_td">
              <div class="clfix">
                <select
                  name=""
                  v-model="font.product.size"
                  style="width: 100px"
                  class="fl_l mr10"
                >
                  <option value="" disabled>글자 크기</option>
                  <template v-for="i in 48" :key="i">
                    <option v-if="i >= 10" :value="i + 'px'">{{ i }}px</option>
                  </template>
                </select>

                <div class="color fl_l">
                  <label for="fontColor"
                    ><input
                      type="text"
                      class="minicolors"
                      v-model="font.product.color"
                      ref="minicolors[0]"
                      :maxlength="7"
                      id="fontColor"
                      @input="onlyHtmlColor($event, 'fontColor')"
                    /><span class="material-icons">color_lens</span></label
                  >
                  <span
                    class="color_preview_box"
                    :style="getBackgroundColor(font.product.color)"
                  ></span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">판매가격</th>
            <td class="input_td">
              <div class="clfix">
                <select
                  name=""
                  v-model="font.price.size"
                  style="width: 100px"
                  class="fl_l mr10"
                >
                  <option value="" disabled>글자 크기</option>
                  <template v-for="i in 48" :key="i">
                    <option v-if="i >= 10" :value="i + 'px'">{{ i }}px</option>
                  </template>
                </select>

                <div class="color fl_l">
                  <label for="sellColor"
                    ><input
                      type="text"
                      class="minicolors"
                      v-model="font.price.color"
                      ref="minicolors[1]"
                      id="sellColor"
                      :maxlength="7"
                      @input="onlyHtmlColor($event, 'sellColor')"
                    /><span class="material-icons">color_lens</span></label
                  >
                  <span
                    class="color_preview_box"
                    :style="getBackgroundColor(font.price.color)"
                  ></span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">상품이미지</th>
            <td class="input_td">
              <div>
                <select name="" v-model="layout_type" style="width: 100%">
                  <option value="cafe24">카페24 상품 이미지 노출</option>
                  <option value="direct">
                    결과화면 노출항목에 등록한 이미지 노출
                  </option>
                </select>
              </div>

              <div v-if="layout_type === 'cafe24'" class="mt10">
                <!--
                가로
                <input
                  type="text"
                  name=""
                  v-model="image_width"
                  :maxlength="4"
                  style="width: 80px"
                />
                px / 세로
                <input
                  type="text"
                  name=""
                  v-model="image_height"
                  :maxlength="4"
                  style="width: 80px"
                />
                px
                -->
              </div>

              <div v-else-if="layout_type === 'direct'" class="mt10">
                <select name="" v-model="select_image" style="width: 100%">
                  <option value="" disabled>선택</option>
                  <template v-for="(item, key) in image_list">
                    <option
                      v-if="item.type === 'image'"
                      :value="key"
                      :key="key"
                    >
                      [이미지] {{ optionTextLength(item.title, 20) }}
                    </option>
                  </template>
                </select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="close"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 폰트/이미지 설정 -->
  </vue-final-modal>
</template>

<script>
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    show_modal: true,
    font: {
      product: { size: '16px', color: '' },
      price: { size: '16px', color: '' },
    },
    layout_type: 'cafe24',
    image_width: '',
    image_height: '',
    select_image: '',
    image_list: [],
  }),
  props: ['survey_config_info', 'result'],
  created: function () {
    this.image_list = JSON.parse(this.survey_config_info.result_item);
    if (this.result.font) {
      this.font.product.size = this.result.font.product.size;
      this.font.product.color = this.result.font.product.color;
      this.font.price.size = this.result.font.price.size;
      this.font.price.color = this.result.font.price.color;
    } else {
      this.font.product.size = '16px';
      this.font.product.color = '#000000';
      this.font.price.size = '16px';
      this.font.price.color = '#ff0000';
    }
    if (this.result.image) {
      this.layout_type = this.result.image.type;
      this.image_width = this.result.image.width;
      this.image_height = this.result.image.height;
      this.direct_image_no = this.result.image.direct_image_no;
      this.select_image = this.result.image.direct_image_no;
    }
  },
  computed: {},
  mounted() {
    this.$nextTick(function () {
      $('#modalResultFont .minicolors').minicolors({
        animationEasing: 'swing',
        keywords: '#',

        change: (hex, opacity) => {
          for (const [key, item] of Object.entries(this.$refs)) {
            if (item.value === hex) {
              if (item.id === 'fontColor') this.font.product.color = hex;
              else if (item.id === 'sellColor') this.font.price.color = hex;
            }
          }
        },
      });

      $('#fontColor').minicolors('value', this.font.product.color);
      $('#sellColor').minicolors('value', this.font.price.color);
    });
  },
  methods: {
    setResult: function () {
      if (this.font.product.color === '') {
        alert('상품명 색상을 입력해주세요.');
        return false;
      } else if (this.font.price.color === '') {
        alert('판매가격 색상을 입력해주세요.');
        return false;
      } else if (
        this.layout_type === 'cafe24' &&
        (this.image_width === '' || this.image_height === '')
      ) {
        // alert('가로/세로 사이즈를 입력해주세요.');
        // return false;
      } else if (this.layout_type === 'direct' && this.select_image === '') {
        alert('결과화면 노출항목에 등록한 이미지를 선택해주세요.');
        return false;
      }

      this.result.font = {
        product: {
          size: this.font.product.size,
          color: this.font.product.color,
        },
        price: {
          size: this.font.price.size,
          color: this.font.price.color,
        },
      };

      if (this.layout_type === 'cafe24') {
        this.select_image = '';
      }

      this.result.image = {
        type: this.layout_type,
        width: this.image_width,
        height: this.image_height,
        direct_image_no: this.select_image,
      };

      this.$emit('updateData', this.result);
      this.show_modal = false;
    },
    onlyHtmlColor: function (event, key) {
      const value = event.target.value.replace(/[^#0-9a-zA-Z]/g, '');

      if (key === 'fontColor') this.font.product.color = value;
      else if (key === 'sellColor') this.font.price.color = value;

      event.target.value = value;
    },

    optionTextLength: function (text, length) {
      if (!length || length == 0) {
        length = 25;
      }
      if (text.length > length) {
        return text.substr(0, length) + '...';
      } else {
        return text;
      }
    },
  },
  watch: {
    image_width: function (val) {
      return (this.image_width = this.image_width.replace(/[^0-9]/g, ''));
    },
    image_height: function (val) {
      return (this.image_height = this.image_height.replace(/[^0-9]/g, ''));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
